<template>
  <button
    :type="type"
    :class="[
      'button-main',
      buttonGrey && 'grey',
      buttonDark && 'dark',
      buttonCancel && 'cancel',
      buttonGreen && 'green',
    ]"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "ButtonMain",
  props: {
    type: {
      type: String,
      default: "button",
    },
    buttonGrey: {
      type: Boolean,
      default: false,
    },
    buttonDark: {
      type: Boolean,
      default: false,
    },
    buttonCancel: {
      type: Boolean,
      default: false,
    },
    buttonGreen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.button-main {
  width: 100%;
  height: 48px;
  padding: 0 20px;
  background: $mainLinkColor;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 5px;
  font-family: $g-font-roboto;
  font-weight: 500;

  &:hover {
    background: $mainSecondColor;
  }

  &:active {
    background: #e4c201;
  }
}
.grey {
  height: 36px;
  background: $mainDisabled;

  &:hover {
    background: #c6c6c6;
  }

  &:active {
    background: #b5b5b5;
  }
}
.dark {
  height: 48px;
  background: $mainColor;
  color: #ffff;

  &:hover {
    background: #000000;
  }

  &:active {
    background: #000000;
  }
}
.cancel {
  height: 36px;
  background: $mainDisabled;

  &:hover {
    background: #c6c6c6;
  }

  &:active {
    background: #b5b5b5;
  }
}

.green {
  background: #daf2d4;

  &:hover {
    background: #daf2d4;
    opacity: 0.7;
  }

  &:active {
    background: #daf2d4;
    opacity: 0.7;
  }
}
</style>
