<template>
  <div class="login">
    <div class="login__container">
      <h1 class="login__title">Вход</h1>
      <form @submit.prevent="handleSubmit">
        <div class="input-container">
          <InputText
            :set-value="formDataToSend"
            :id="'email'"
            :label="'email'"
            :type="'email'"
            :placeholder="'Email'"
            :error-messages="error['email']"
            isBordered
            isAverage
          />
        </div>
        <div class="input-container">
          <InputText
            :set-value="formDataToSend"
            :id="'password'"
            :label="'password'"
            :type="'password'"
            :placeholder="'Пароль'"
            :error-messages="error['password']"
            isBordered
            isAverage
          />
        </div>
        <ButtonMain :type="'submit'"> Войти </ButtonMain>
      </form>
    </div>
    <FetchSpinnerModal v-if="loadingProcess" />
  </div>
</template>

<script>
import { mapState } from "vuex";

import FetchSpinnerModal from "../components/molecules/FetchSpinnerModal.vue";
import ButtonMain from "../components/atoms/buttons/ButtonMain.vue";
import InputText from "../components/atoms/inputs/InputText.vue";
export default {
  name: "Login",
  data() {
    return {
      formDataToSend: {},
    };
  },
  methods: {
    handleSubmit() {
      this.$store.dispatch("user/logIn", this.formDataToSend);
    },
  },
  components: {
    ButtonMain,
    InputText,
    FetchSpinnerModal,
  },
  computed: {
    ...mapState("user", ["error", "userInfo", "loadingProcess"]),
  },
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  &__container {
    width: 400px;
    padding-bottom: 150px;
  }

  &__title {
    padding-bottom: 20px;
    text-align: center;
    font-size: $g-font-second-size;
    font-weight: 400;
  }
}
.input-container {
  padding-bottom: 20px;
}
</style>
